@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&family=Special+Elite&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif !important;
}

.notification-type-success {
  border-left: 6px solid #4bb543;
}

.notification-type-error {
  border-left: 6px solid red;
}
.ant-table thead > tr > th::before {
  display: none;
}
::-moz-selection {
  /* Code for Firefox */
  color: white;
  background: #0ca940;
}

::selection {
  color: white;
  background: #0ca940;
}
